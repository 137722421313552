<template>
  <BRow class="rowDark">
    <BCol style="display: flex; justify-content: flex-end">
      <CarSearch :size="80" />
    </BCol>
    <BCol style="display: flex; justify-content: center">
      <AccountEye :size="80" />
    </BCol>
    <BCol style="display: flex; justify-content: flex-start">
      <CurrencyUsdOff :size="80" />
    </BCol>
  </BRow>
  <BRow>
    <BCol><div class="carr">&nbsp;</div></BCol>
  </BRow>
  <BRow class="rowDark">
    <BCol style="display: flex; justify-content: flex-end">
      {{ $t("CheckedVehicles") }}
    </BCol>
    <BCol style="display: flex; justify-content: center">
      {{ $t("Tracking247") }}
    </BCol>
    <BCol style="display: flex; justify-content: flex-start">
      {{ $t("FreeCancellation") }}
    </BCol>
  </BRow>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue-next";
import CarSearch from "vue-material-design-icons/CarSearch.vue";
import AccountEye from "vue-material-design-icons/AccountEye.vue";
import CurrencyUsdOff from "vue-material-design-icons/CurrencyUsdOff.vue";

import router from "../../router";
export default {
  name: "OptionsBar",
  components: {
    BRow,
    BCol,
    CarSearch,
    AccountEye,
    CurrencyUsdOff,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    goBack() {
      if (this.currentRouteName == "Manage") {
        this.$router.push({ name: "Home" });
      } else {
        router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.carr {
  width: 100%;
  background-image: url("../../assets/images/utils/road.png");
  background-repeat: repeat-x; /* Repetir solo en la dirección horizontal */
  background-size: auto 100%; /* Ajustar la altura de la imagen al contenedor, mantener la proporción del ancho */
  background-position: left center;
}
</style>
