<template>
  <div class="dropdown">
    <button
      class="btn btn-primary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ selectedCurrency.symbol }} {{ selectedCurrency.code }}
    </button>
    <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton">
      <div class="row row-cols-3 g-2">
        <div v-for="currency in currencies" :key="currency.code" class="col">
          <button
            class="btn btn-outline-primary w-100"
            @click="selectCurrency(currency)"
          >
            <strong>{{ currency.symbol }}</strong>
            <small class="d-block">{{ currency.code }}</small>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrencyDropdown",
  props: {
    title: String,
    constraint: Object,
    value: {
      Type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedCurrency: { code: "USD", symbol: "$" },
      currencies: [
        { code: "USD", symbol: "$", name: "US Dollar" },
        { code: "EUR", symbol: "€", name: "Euro" },
        { code: "JPY", symbol: "¥", name: "Japanese Yen" },
        { code: "GBP", symbol: "£", name: "British Pound" },
        { code: "AUD", symbol: "A$", name: "Australian Dollar" },
        { code: "CAD", symbol: "C$", name: "Canadian Dollar" },
        { code: "CHF", symbol: "Fr", name: "Swiss Franc" },
        { code: "CNY", symbol: "¥", name: "Chinese Yuan" },
        { code: "HKD", symbol: "HK$", name: "Hong Kong Dollar" },
        { code: "NZD", symbol: "NZ$", name: "New Zealand Dollar" },
      ],
    };
  },
  methods: {
    selectCurrency(currency) {
      this.selectedCurrency = currency;
      this.$emit("currency-changed", currency);
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  min-width: 300px;
}
.btn-outline-primary {
  font-size: 0.9rem;
  padding: 0.25rem 0.5rem;
}
</style>
