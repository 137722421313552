import { createI18n } from "vue-i18n";
import messages from "./locales";

const i18n = createI18n({
  locale: "en", // Idioma por defecto
  fallbackLocale: "es", // Idioma de respaldo si la clave no está en el idioma actual
  messages, // Aquí es donde se pasan las traducciones al objeto i18n
});

export default i18n;
