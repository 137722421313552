<template>
  <div class="autocomplete">
    <BFormGroup>
      <BFormInput
        :placeholder="title"
        aria-placeholder="PATATA"
        @input="handleInput"
        @blur="handleBlur"
        v-model="searchTerm"
        required
        prepend="@"
      />
      <transition name="fade">
        <ul
          v-if="searchAirports.length && showDropdown"
          class="autocomplete-items"
        >
          <li
            v-for="airport in searchAirports"
            :key="airport.objectID"
            @click="selectAirport(airport)"
          >
            <span v-if="airport.name != airport.city" class="city"
              >{{ airport.city }},
            </span>
            <span class="name">{{ airport.name }}</span>
            <span class="code">({{ airport.iata_code }})</span>
          </li>
        </ul>
      </transition>
    </BFormGroup>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import airports from "../../data/airports.json";
import { BFormGroup, BFormInput } from "bootstrap-vue-next";

export default {
  name: "AirportAutoComplete",
  props: {
    title: String,
    constraint: Object,
    value: {
      Type: String,
      default: "",
    },
  },
  components: {
    BFormGroup,
    BFormInput,
  },
  emits: ["triggerChange"],
  setup(props, { emit }) {
    let searchTerm = ref(props.value);
    let showDropdown = ref(false);

    const searchAirports = computed(() => {
      if (searchTerm.value === "") {
        return [];
      }

      let matches = 0;

      return airports.filter((airport) => {
        if (
          airport.name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          airport.city.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
          (airport.iata_code
            .toLowerCase()
            .includes(searchTerm.value.toLowerCase()) &&
            matches < 10)
        ) {
          matches++;
          return airport;
        }
      });
    });

    const selectAirport = (airport) => {
      emit("triggerChange", airport);
      showDropdown.value = false;
      searchTerm.value = `${
        airport.name != airport.city ? airport.city + ", " : ""
      }${airport.name} (${airport.iata_code})`;
    };

    const handleInput = () => {
      showDropdown.value = true;
    };

    const handleBlur = () => {
      setTimeout(() => {
        showDropdown.value = false;
      }, 200);
    };

    return {
      showDropdown,
      searchTerm,
      searchAirports,
      selectAirport,
      handleInput,
      handleBlur,
    };
  },
};
</script>

<style scoped></style>
