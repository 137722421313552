<template>
  <BRow class="mt-3" style="margin-bottom: 20px">
    <BCol
      cols="12"
      md="4"
      class="d-flex flex-column align-items-center justify-content-center mb-4 mb-md-0"
    >
      <BRow class="mb-2">
        <BCol class="d-flex justify-content-center">
          <img
            src="../../assets/images/carTypes/economy.png"
            style="height: 100px"
          />
        </BCol>
      </BRow>
      <BRow class="mb-2">
        <BCol class="text-center fw-bold">
          {{ $t("typeEconomy") }}
        </BCol>
      </BRow>
      <BRow>
        <BCol class="text-center">
          {{ $t("typeEconomyText") }}
        </BCol>
      </BRow>
    </BCol>

    <BCol
      cols="12"
      md="4"
      class="d-flex flex-column align-items-center justify-content-center mb-4 mb-md-0"
    >
      <BRow class="mb-2">
        <BCol class="d-flex justify-content-center">
          <img
            src="../../assets/images/carTypes/business.png"
            style="height: 100px"
          />
        </BCol>
      </BRow>
      <BRow class="mb-2">
        <BCol class="text-center fw-bold">
          {{ $t("typeBusiness") }}
        </BCol>
      </BRow>
      <BRow>
        <BCol class="text-center">
          {{ $t("typeBusinessText") }}
        </BCol>
      </BRow>
    </BCol>

    <BCol
      cols="12"
      md="4"
      class="d-flex flex-column align-items-center justify-content-center"
    >
      <BRow class="mb-2">
        <BCol class="d-flex justify-content-center">
          <img
            src="../../assets/images/carTypes/groups.png"
            style="height: 100px"
          />
        </BCol>
      </BRow>
      <BRow class="mb-2">
        <BCol class="text-center fw-bold">
          {{ $t("typeGroups") }}
        </BCol>
      </BRow>
      <BRow>
        <BCol class="text-center">
          {{ $t("typeGroupsText") }}
        </BCol>
      </BRow>
    </BCol>
  </BRow>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue-next";

export default {
  name: "BannerCarType",
  components: {
    BRow,
    BCol,
  },
};
</script>

<style scoped>
.diplayF {
  justify-content: center;
}
</style>
