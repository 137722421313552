<template>
  <PageHeader msg="RidesAndGO" />
  <BannerHome msg="" />
  <BannerCarType />
  <JuntoATiBar />
  <ModifResBar />
  <OptionsBar />
  <CustomerReviews />
  <SubsribeNews />
  <FooterNav />
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/parts/PageHeader.vue";
import BannerHome from "@/components/parts/BannerHome.vue";
import CustomerReviews from "@/components/parts/CustomerReviews.vue";
import FooterNav from "@/components/parts/FooterNav.vue";
import SubsribeNews from "@/components/parts/SubsribeNews.vue";
import OptionsBar from "@/components/parts/OptionsBar.vue";
import ModifResBar from "@/components/parts/ModifResBar.vue";
import JuntoATiBar from "@/components/parts/JuntoATiBar.vue";
import BannerCarType from "@/components/parts/BannerCarType.vue";

//https://pictogrammers.com/library/mdi/
/*
  <div>
    <alarm-icon :size="36" class="icon" />
    <basket-icon :size="36" class="icon" />
  </div>
 */

export default {
  name: "homeRides",
  props: {
    msg: String,
  },
  components: {
    PageHeader,
    BannerHome,
    CustomerReviews,
    OptionsBar,
    ModifResBar,
    SubsribeNews,
    FooterNav,
    JuntoATiBar,
    BannerCarType,
  },
  mounted() {
    document.body.classList.add("homeRides");
  },
};
</script>
