<template>
  <BRow style="align-items: center; margin: 10px">
    <BCol md="4" style="display: flex; justify-content: flex-end">
      <img src="../../assets/images/PlaneRoute.png" style="height: 150px" />
    </BCol>
    <BCol md="5">
      <BRow style="align-items: center">
        <BCol class="font-size-30" style="text-align: center">
          {{ $t("modifResTitle") }}
        </BCol>
      </BRow>
      <BRow>
        <BCol style="text-align: center">
          {{ $t("modifResText") }}
        </BCol>
      </BRow>
    </BCol>
  </BRow>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue-next";

import router from "../../router";
export default {
  name: "ModifResBar",
  components: {
    BRow,
    BCol,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    goBack() {
      if (this.currentRouteName == "Manage") {
        this.$router.push({ name: "Home" });
      } else {
        router.go(-1);
      }
    },
  },
};
</script>

<style scoped></style>
