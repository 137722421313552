<template>
  <BRow class="rowDark">
    <BCol md="2" class="colimg font-size-12">
      <img src="../../assets/logoDark.png" style="height: 50px" />
    </BCol>
    <BCol>
      <BRow>
        <BCol md="3">
          <h3><CompanyIcon :size="30" /> {{ $t("footer_Company") }}</h3>
          <ul>
            <li>
              <RouterLink to="/about">{{ $t("footer_About_us") }}</RouterLink>
            </li>
            <li>
              <RouterLink to="/">{{ $t("footer_Community_Blog") }}</RouterLink>
            </li>
            <li>
              <RouterLink to="/">{{ $t("footer_Rewards") }}</RouterLink>
            </li>
            <li>
              <RouterLink to="/">{{ $t("footer_Meet_the_Team") }}</RouterLink>
            </li>
          </ul>
        </BCol>
        <BCol md="4">
          <h3><SupportIcon :size="30" /> {{ $t("footer_Support") }}</h3>
          <ul>
            <li>
              <RouterLink to="/">{{ $t("footer_Legal") }}</RouterLink>
            </li>
            <li>
              <RouterLink to="/">{{ $t("footer_Contact") }}</RouterLink>
            </li>
            <li>
              <RouterLink to="/">{{
                $t("footer_Affiliate_Program")
              }}</RouterLink>
            </li>
            <li>
              <RouterLink to="/">{{ $t("footer_Privacy_Policy") }}</RouterLink>
            </li>
          </ul>
        </BCol>
        <BCol md="5">
          <h3><PlaneCarIcon :size="30" /> {{ $t("footer_Work_with_Us") }}</h3>
          <ul>
            <li>
              <RouterLink to="/"
                ><InfoIcon :size="20" /> {{ $t("footer_Info") }}</RouterLink
              >
            </li>
            <li>
              <RouterLink to="/"
                ><FacebookIcon :size="20" />
                {{ $t("footer_Follow_us_in_Linkedin") }}</RouterLink
              >
            </li>
            <li>
              <RouterLink to="/"
                ><TwitterIcon :size="20" />
                {{ $t("footer_Follow_us_in_X") }}</RouterLink
              >
            </li>
            <li>
              <RouterLink to="/"
                ><LinkedinIcon :size="20" />
                {{ $t("footer_Follow_us_in_Facebook") }}</RouterLink
              >
            </li>
          </ul>
        </BCol>
      </BRow>
    </BCol>
  </BRow>
  <BRow class="rowDark font-size-12">
    <BCol md="6">
      Email us
      <a href="mailto:support@ridesandgo.com">support@ridesandgo.com</a>
    </BCol>
    <BCol md="6" style="display: flex; justify-content: flex-end">
      <span>© 2024 by <a href="/">RidesAndGo</a> </span>
    </BCol>
  </BRow>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue-next";
import FacebookIcon from "vue-material-design-icons/Facebook.vue";
import InfoIcon from "vue-material-design-icons/BookInformationVariant.vue";
import LinkedinIcon from "vue-material-design-icons/Linkedin.vue";
import TwitterIcon from "vue-material-design-icons/Twitter.vue";
import PlaneCarIcon from "vue-material-design-icons/PlaneCar.vue";
import SupportIcon from "vue-material-design-icons/FaceAgent.vue";
import CompanyIcon from "vue-material-design-icons/Handshake.vue";

import router from "../../router";
export default {
  name: "FooterNav",
  components: {
    BRow,
    BCol,
    InfoIcon,
    FacebookIcon,
    LinkedinIcon,
    TwitterIcon,
    PlaneCarIcon,
    SupportIcon,
    CompanyIcon,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    goBack() {
      if (this.currentRouteName == "Manage") {
        this.$router.push({ name: "Home" });
      } else {
        router.go(-1);
      }
    },
  },
};
</script>

<style scoped></style>
