<template>
  <div class="banner-carousel">
    <BCarousel fade :interval="slideInterval" ride="carousel" no-touch>
      <BCarouselSlide
        v-for="slide in slides"
        :key="`slide-${slide}`"
        :img-src="`./img/carrousel/${slide}.jpeg`"
      />
      <!--<BCarouselSlide img-src="./img/carrousel/1.jpeg" />
       <BCarouselSlide img-src="./img/carrousel/2.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/3.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/4.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/5.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/6.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/7.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/8.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/9.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/10.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/11.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/12.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/13.jpeg" />
      <BCarouselSlide img-src="./img/carrousel/14.jpeg" />-->
    </BCarousel>
    <div class="banner-title">
      <h1>{{ $t("homeslogan") }}</h1>
      <h2>{{ $t("homecallToAction") }}</h2>
    </div>
    <SearchForm />
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide } from "bootstrap-vue-next";
import SearchForm from "@/components/SearchForm.vue";

export default {
  name: "BannerHome",
  props: {
    msg: String,
  },
  components: {
    BCarousel,
    BCarouselSlide,
    SearchForm,
  },
  data() {
    return {
      slideInterval: 2000, // Ajuste a 2 segundos
      slides: Array.from({ length: 19 }, (_, i) => i + 1),
    };
  },
};
</script>

<style scoped></style>
