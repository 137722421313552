import { createApp } from "vue";
import { createBootstrap } from "bootstrap-vue-next";
import store from "./store";
import i18n from "./i18n";
//import VueGoogleMaps from "@fawmi/vue-google-maps";

import App from "./App.vue";
import router from "./router";
import "./assets/sass/app.scss";

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(createBootstrap())

  .mount("#app");
/*
.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GMAPS_API, //"", // Utiliza el API Key desde .env
    },
  })
    */
