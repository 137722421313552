<template>
  <div class="autocomplete">
    <BFormGroup>
      <BFormInput
        :placeholder="title"
        @input="onInput"
        @blur="handleBlur"
        v-model="searchTerm"
        required
      >
        <MapMarkRadiusIco size="26" class="icon" />
      </BFormInput>
      <transition name="fade">
        <ul v-if="locations.length && showDropdown" class="autocomplete-items">
          <li
            v-for="(location, index) in locations"
            :key="location['@Code']"
            @click="selectLocation(location)"
            :class="{ active: index === activeIndex }"
            @mouseover="activeIndex = index"
          >
            {{ location["@Name"] }}
          </li>
        </ul>
      </transition>
    </BFormGroup>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from "bootstrap-vue-next";
export default {
  name: "LocationsAutoComplete",
  props: {
    title: String,
    constraint: Object,
    dropOffAddress: { type: String, default: "" },
  },
  components: {
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      searchTerm: this.dropOffAddress,
      showDropdown: false,
      locations: [],
      activeIndex: -1,
    };
  },
  emits: ["triggerChange"],
  methods: {
    onInput(input) {
      if (input.target.value.length > 3) {
        this.fetchData(input.target.value);
      } else {
        this.showDropdown = false;
        this.locations = [];
      }
    },
    async getLocation(id) {
      let _url =
        "https://otageo.cartrawler.com/cartrawlerota/json?type=CT_UtilRQ";
      let _data = {
        "@Target": "Production",
        "@PrimaryLangID": "en",
        POS: {
          Source: [
            {
              "@ERSP_UserID": "MP",
              "@ISOCurrency": "EUR",
              RequestorID: {
                "@Type": "16",
                "@ID": "68622",
                "@ID_Context": "CARTRAWLER",
              },
            },
          ],
        },
        "@xmlns": "http://www.cartrawler.com/",
        "@Version": "1.000",
        Action: {
          "@Utility": "DecodeGoogleId",
          Value: id,
        },
      }; //end data

      const res = await fetch(_url, {
        method: "POST",
        headers: {},
        body: JSON.stringify(_data),
      });

      const data = await res.json();
      return data;
    },
    //Fetch data from CT locations API
    async fetchData(term) {
      this.showDropdown = true;
      let _url =
        "https://otageo.cartrawler.com/cartrawlerota/json?type=CT_VehLocSearchRQ";
      let _data = {
        "@Target": "Production",
        "@PrimaryLangID": "en",
        POS: {
          Source: [
            {
              "@ERSP_UserID": "MP",
              "@ISOCurrency": "EUR",
              RequestorID: {
                "@Type": "16",
                "@ID": "68622",
                "@ID_Context": "CARTRAWLER",
              },
            },
          ],
        },
        "@xmlns": "http://www.cartrawler.com/",
        "@Version": "1.000",
        VehLocSearchCriterion: {
          "@ExactMatch": "true",
          "@ImportanceType": "Mandatory",
          PartialText: {
            "@Sort": "1",
            "@Size": 15,
            "@POITypes": "1,3,8",
            "#text": term,
          },
        },
      };
      //Add geo contraint to make results more accurate
      if (this.constraint.lat != undefined) {
        _data.VehLocSearchCriterion.PartialText["@Latitude"] =
          this.constraint.lat;
        _data.VehLocSearchCriterion.PartialText["@Longitude"] =
          this.constraint.lng;
      }
      const res = await fetch(_url, {
        method: "POST",
        headers: {},
        body: JSON.stringify(_data),
      });

      const data = await res.json();
      this.locations = data.VehMatchedLocs.LocationDetail;
    },
    async selectLocation(location) {
      this.searchTerm = location["@Name"];
      //If there is no geo coordinated, retrieve them from a seperate CT endpoint
      if (typeof location["@ExternalLocId"] != "undefined") {
        let newLocation = await this.getLocation(location["@ExternalLocId"]);
        //Update location with new info
        location["@Lat"] = newLocation.Result.Lat;
        location["@Lng"] = newLocation.Result.Lng;
        location["@Name"] = newLocation.Result.LocationName;
        location["@Address"] = newLocation.Result.Address;
      }
      //Hide dropdown and pass info back up
      this.showDropdown = false;
      this.$emit("triggerChange", location);
    },
    handleBlur() {
      setTimeout(() => {
        if (this.locations.length && this.activeIndex === -1) {
          this.selectLocation(this.locations[0]);
        } else {
          this.showDropdown = false;
        }
      }, 200);
    },
  },
};
</script>
