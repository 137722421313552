<template>
  <div class="customer-reviews bg-light py-5">
    <div class="container">
      <h2 class="text-center mb-5">
        <Star />{{ $t("homeTitleReviews") }}
        <Star />
      </h2>
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div v-for="(review, index) in reviews" :key="index" class="col">
          <div class="card h-100 shadow-sm">
            <div class="card-body">
              <div
                class="d-flex justify-content-between align-items-center mb-2"
              >
                <h5 class="card-title mb-0">{{ review.name }}</h5>
                <div class="text-warning">
                  <Star v-for="n in review.rating" :key="n" />
                  <Star
                    v-for="n in 5 - review.rating"
                    :key="n"
                    fillColor="gray"
                  />
                </div>
              </div>
              <p class="card-text">{{ review.comment }}</p>
            </div>
            <div class="card-footer bg-transparent text-muted">
              <small>{{ review.date }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Star from "vue-material-design-icons/Star.vue";
//import { useI18n } from "vue-i18n";
import reviewsEn from "../../locales/reviews/en.json";
import reviewsEs from "../../locales/reviews/es.json";
import reviewsDe from "../../locales/reviews/de.json";
import reviewsFr from "../../locales/reviews/fr.json";

export default {
  name: "CustomerReviews",
  components: {
    Star,
  },
  computed: {
    reviews() {
      switch (this.$i18n.locale) {
        case "es":
          return reviewsEs;
        case "en":
          return reviewsEn;
        case "fr":
          return reviewsFr;
        case "de":
          return reviewsDe;
        default:
          return reviewsEn; // Idioma por defecto
      }
    },
  },
};
</script>

<style scoped>
.customer-reviews {
  background-color: #f8f9fa;
}
.card {
  transition: transform 0.2s;
}
.card:hover {
  transform: translateY(-5px);
}
</style>
