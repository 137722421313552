<template>
  <BRow class="imageRow">
    <BCol md="3" />
    <BCol md="6">
      <BRow style="align-items: center">
        <BCol class="font-size-30" style="text-align: center">
          {{ $t("homeBannerJuntoTitle") }}
        </BCol>
      </BRow>
      <BRow>
        <BCol style="text-align: center">
          {{ $t("homeBannerJuntoText") }}
        </BCol>
      </BRow>
    </BCol>
    <BCol md="3" />
  </BRow>
</template>

<script>
import { BCol, BRow } from "bootstrap-vue-next";

import router from "../../router";
export default {
  name: "JuntoATiBar",
  components: {
    BRow,
    BCol,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    goBack() {
      if (this.currentRouteName == "Manage") {
        this.$router.push({ name: "Home" });
      } else {
        router.go(-1);
      }
    },
  },
};
</script>

<style scoped>
.imageRow {
  align-items: center;
  margin: 0px;
  padding: 40px;
  position: relative;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url("../../assets/images/bannerjunto.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
  color: white; /* Opcional: Para asegurar que el texto sea legible sobre el fondo */
}
</style>
