<template>
  <BNavbar toggleable="sm" type="dark" variant="faded">
    <!-- Marca (Brand) -->
    <BNavbarBrand>
      <BNavItem href="/"
        ><img
          src="../../assets/logoDark.png"
          style="height: 35px; margin-left: 30px"
      /></BNavItem>
    </BNavbarBrand>

    <!-- Toggler (botón) -->
    <BNavbarToggle target="navbarsHeader" class="ms-auto" />

    <!-- Contenido colapsable -->
    <BCollapse id="navbarsHeader" is-nav>
      <!-- Primer grupo de elementos de la barra de navegación -->
      <BNavbarNav class="ms-5 mb-3 mb-xl-0 me-auto" style="padding-left: 5%">
        <BNavItem href="#">
          <FacebookIcon :size="25" />
        </BNavItem>
        <BNavItem href="#">
          <LinkedinIcon :size="25" />
        </BNavItem>
        <BNavItem href="#">{{ $t("homeFindRes") }}</BNavItem>
        <BNavItem href="#"> {{ $t("homeagencies") }} </BNavItem>
        <BNavItem href="#"> {{ $t("homeproviders") }} </BNavItem>
        <BNavItem href="#"> {{ $t("homehelpcenter") }} </BNavItem>
      </BNavbarNav>

      <!-- Segundo grupo de elementos de la barra de navegación (al lado derecho) -->
      <BNavbarNav class="mx-5 mb-0 mb-xl-0" style="padding-right: 10%">
        <!-- Dropdown de idioma -->
        <BNavItemDropdown right>
          <template #button-content>
            <img :src="selectedFlag" width="25" />
          </template>
          <BDropdownItem @click="changeLanguage('en')"
            ><img src="../../assets/images/flag/en.svg" width="25" />
            English</BDropdownItem
          >
          <BDropdownItem @click="changeLanguage('es')"
            ><img src="../../assets/images/flag/es.svg" width="25" />
            Español</BDropdownItem
          >
          <BDropdownItem @click="changeLanguage('fr')"
            ><img src="../../assets/images/flag/fr.svg" width="25" />
            Français</BDropdownItem
          >
          <BDropdownItem @click="changeLanguage('de')"
            ><img src="../../assets/images/flag/de.svg" width="25" />
            Deutsh</BDropdownItem
          >
        </BNavItemDropdown>

        <!-- Dropdown de moneda -->
        <CurrencyDropdown @currency-changed="handleCurrencyChange" />
        <!-- Enlaces de navegación -->
        <BNavItem href="#">Login</BNavItem>
        <BNavItem href="#">SignUp</BNavItem>
      </BNavbarNav>
    </BCollapse>
  </BNavbar>
</template>

<script>
import FacebookIcon from "vue-material-design-icons/Facebook.vue";
import LinkedinIcon from "vue-material-design-icons/Linkedin.vue";

import {
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BNavbarNav,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue-next";
import enFlag from "../../assets/images/flag/en.svg";
import esFlag from "../../assets/images/flag/es.svg";
import frFlag from "../../assets/images/flag/fr.svg";
import deFlag from "../../assets/images/flag/de.svg";

import CurrencyDropdown from "../subComponents/CurrencyDropdown .vue";

//import { RouterLink } from "vue-router";

export default {
  name: "PageHeader",
  props: {
    msg: String,
  },
  data() {
    return {
      // Establece la bandera predeterminada (puede ser 'en' por defecto)
      selectedFlag: enFlag,
      currentCurrency: { code: "USD", symbol: "$" },
    };
  },
  methods: {
    handleCurrencyChange(newCurrency) {
      this.currentCurrency = newCurrency;
    },
    changeLanguage(lang) {
      //alert(this.selectedFlag);
      this.$i18n.locale = lang; // Cambia el idioma utilizando Vue I18n
      switch (lang) {
        case "en":
          this.selectedFlag = enFlag;
          break;
        case "es":
          this.selectedFlag = esFlag;
          break;
        case "fr":
          this.selectedFlag = frFlag;
          break;
        case "de":
          this.selectedFlag = deFlag;
          break;
      }
    },
  },
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BCollapse,
    BNavbarNav,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    FacebookIcon,
    LinkedinIcon,
    CurrencyDropdown,
  },
};
</script>
