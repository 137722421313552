<template>
  <BForm BForm @submit="onSubmit" class="rowDark">
    <BFormRow style="align-items: center">
      <BCol text-end md="3" class="text-light float-end text-end">
        <Rss size="32" />
      </BCol>
      <BCol md="3">
        <h4>Get News &amp; More</h4>
      </BCol>
      <BCol md="3">
        <BFormGroup
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
          description="We'll never share your email with anyone else."
        >
          <BFormInput
            id="input-1"
            type="email"
            placeholder="Enter email"
            required
          />
        </BFormGroup>
      </BCol>
      <BCol md="3">
        <BButton type="submit" onclick="onSubmit" variant="secondary">
          Subscribe
        </BButton>
      </BCol>
    </BFormRow>
  </BForm>
</template>

<script>
import {
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRow,
} from "bootstrap-vue-next";
import Rss from "vue-material-design-icons/Rss.vue";

import router from "../../router";
export default {
  name: "SubscribeNews",
  components: {
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRow,
    Rss,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    goBack() {
      if (this.currentRouteName == "Manage") {
        this.$router.push({ name: "Home" });
      } else {
        router.go(-1);
      }
    },
    onSubmit(e) {
      e.preventDefault();
      alert("ok");
      ///newsletter/subscribe
    },
  },
};
</script>

<style scoped></style>
