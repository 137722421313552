<template>
  <!-- Formulario de Búsqueda -->
  <BRow class="search-form-container">
    <BRow>
      <BCol md="12" lg="12">
        <BCard class="mb-4 search-form" style="margin: 0; padding: 0">
          <BForm
            @submit="onSubmit"
            autocomplete="off"
            class="form"
            method="get"
            style="margin: 0px; padding: 0px"
          >
            <div class="searchTab">
              <div class="col-10">
                <PlaneCarIco size="32" style="margin-left: 10px" />
                <span v-if="oneWay"> Transfers - Ida y vuelta </span>
                <span v-else> Transfers - Solo ida </span>
              </div>
              <div class="col-2">
                <BFormCheckbox switch size="lg" v-model="oneWay" id="oneWay" />
              </div>
            </div>
            <BFormRow
              style="
                background-color: #fff;
                margin: 0;
                padding: 0;
                border-color: grey;
                border-width: 1px;
                border-style: solid;
                border-radius: 0 8px 8px 8px;
              "
            >
              <BCol md="10">
                <BFormRow style="background-color: #fff; margin: 10px">
                  <BCol md="6" style="display: flex">
                    <MapMarkRadiusIco size="26" class="icon" />
                    <AirportAutoComplete
                      :title="'From where / PickUp airport'"
                      :constraint="constraint"
                      @triggerChange="updatePickupLocation"
                      :value="
                        pickupIATA == '' ? '' : `${pickupName} (${pickupIATA})`
                      "
                      style="width: 100%"
                    />
                  </BCol>
                  <BCol md="6" style="display: flex">
                    <MapMarkRadiusIco size="26" class="icon" />
                    <LocationsAutoComplete
                      :title="'To where / Drop off Location'"
                      :constraint="constraint"
                      :dropOffAddress="dropOffAddress"
                      @triggerChange="updateDropoffLocation"
                      style="width: 100%"
                    />
                  </BCol>
                </BFormRow>
                <BFormRow>
                  <BCol md="1">
                    <label size="sm">Depart </label>
                  </BCol>
                  <BCol md="3">
                    <BFormGroup label-for="departDate" size="sm">
                      <BFormInput
                        id="departDate"
                        type="date"
                        v-model="pickupDate"
                        :required="true"
                        placeholder="Pickup Date"
                        :min="dateMin"
                        :max="dateMax"
                        size="sm"
                      />
                    </BFormGroup>
                  </BCol>
                  <BCol md="2">
                    <BFormGroup label-for="time" size="sm">
                      <BFormInput
                        id="time"
                        type="time"
                        v-model="pickupTime"
                        :required="true"
                        placeholder="Pickup Time"
                        size="sm"
                      />
                    </BFormGroup>
                  </BCol>
                  <BCol md="1">
                    <label v-if="oneWay">Return </label>
                  </BCol>
                  <BCol md="3">
                    <BFormGroup label-for="returnDate" v-if="oneWay" size="sm">
                      <BFormInput
                        id="returnDate"
                        type="date"
                        v-model="returnDate"
                        :value="returnDate"
                        :required="!oneWay"
                        size="sm"
                        :min="dateMin"
                        :max="dateMax"
                      />
                    </BFormGroup>
                  </BCol>
                  <BCol md="2">
                    <BFormGroup label-for="returnTime" v-if="oneWay">
                      <BFormInput
                        id="returnTime"
                        type="time"
                        v-model="returnTime"
                        :required="!oneWay"
                        size="sm"
                      />
                    </BFormGroup>
                  </BCol>
                </BFormRow>
              </BCol>
              <BCol md="1">
                <label>Passegers</label>
                <BFormGroup label-for="passengers">
                  <PassengerSelect
                    @triggerChange="updatePassengers"
                    :pax="passengers"
                  />
                </BFormGroup>
              </BCol>
              <BCol md="1" style="margin: 0; padding: 0">
                <BButton type="submit" variant="primary" class="Searcbutton">
                  Search
                </BButton>
              </BCol>
            </BFormRow>
          </BForm>
        </BCard>
      </BCol>
    </BRow>
  </BRow>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRow,
  BFormCheckbox,
} from "bootstrap-vue-next";
import AirportAutoComplete from "./subComponents/AirportAutoComplete.vue";
import LocationsAutoComplete from "./subComponents/LocationsAutoComplete.vue";
import PassengerSelect from "./subComponents/PassengerSelect.vue";
import router from "../router";
import PlaneCarIco from "vue-material-design-icons/PlaneCar.vue";
import MapMarkRadiusIco from "vue-material-design-icons/MapMarkerRadius.vue";

const locationTypes = {
  1: "Airport",
  3: "Vicinity",
  8: "Vicinity",
};
export default {
  name: "SearchForm",
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRow,
    BFormCheckbox,
    AirportAutoComplete,
    LocationsAutoComplete,
    PlaneCarIco,
    MapMarkRadiusIco,
    PassengerSelect,
  },
  data() {
    let _data = this.$store.getters.searchData;
    let nowMin = new Date();
    let nowMax = new Date();
    let nowTo = new Date();
    nowMax.setDate(nowMax.getDate() + 365); //Añadimos un año
    nowTo.setDate(nowTo.getDate() + 15); //Añadimos un año

    const formatDate = (date) => date.toISOString().split("T")[0];
    return {
      fromWhere: "",
      toWhere: "",
      departDate: "",
      time: "",
      dateMin: formatDate(nowMin),
      dateMax: formatDate(nowMax),
      pickupIATA: _data == null ? "" : _data.pickupIATA,
      pickupName: _data == null ? "" : _data.pickupName,
      pickupLat: _data == null ? "" : _data.pickupLat,
      pickupLng: _data == null ? "" : _data.pickupLng,
      dropOffLat: _data == null ? "" : _data.dropOffLat,
      dropOffLng: _data == null ? "" : _data.dropOffLng,
      dropOffAddress: _data == null ? "" : _data.dropOffAddress,
      dropOffType: _data == null ? "" : _data.dropOffType,
      pickupDate: _data == null ? formatDate(nowMin) : _data.pickupDate,
      returnDate:
        _data == null || _data.returnDate == null
          ? formatDate(nowTo)
          : _data.returnDate,
      pickupTime: _data == null ? "10:00:00" : _data.pickupTime,
      returnTime:
        _data == null || _data.returnTime == null
          ? "10:00:00"
          : _data.returnTime,
      oneWay: _data == null ? true : _data.oneWay,
      passengers: _data == null ? 1 : _data.passengers,
      constraint: {},
    };
  },
  methods: {
    updateToggle(result) {
      this.oneWay = result.target.value == "oneway" ? true : false;
    },
    updatePickupLocation(airport) {
      this.pickupName = airport.name;
      this.pickupIATA = airport.iata_code;
      this.pickupLat = airport._geoloc.lat;
      this.pickupLng = airport._geoloc.lng;
      this.constraint = airport._geoloc;
    },
    updateDropoffLocation(location) {
      this.dropOffLat = location["@Lat"];
      this.dropOffLng = location["@Lng"];
      this.dropOffAddress = location["@Name"];
      this.dropOffType = locationTypes[location["@Type"]];
    },
    updatePassengers(pax) {
      this.passengers = pax;
    },
    onSubmit(e) {
      e.preventDefault();
      //!todo some VALIDATION!
      console.log("Search parameters:", this.search);
      let queryParams = {
        pickupIATA: this.pickupIATA,
        pickupName: this.pickupName,
        pickupLat: this.pickupLat,
        pickupLng: this.pickupLng,
        dropOffLat: this.dropOffLat,
        dropOffLng: this.dropOffLng,
        dropOffAddress: this.dropOffAddress,
        dropOffType: this.dropOffType,
        pickupDate: this.pickupDate,
        pickupTime: this.pickupTime,
        passengers: this.passengers,
      };

      //If return journey, pass additional return info
      if (!this.oneWay) {
        queryParams.returnDate = this.returnDate;
        queryParams.returnTime = this.returnTime;
      }
      //alert('Searchform_Sumit');
      router.push({
        name: "SearchResults",
        query: queryParams,
      });
    },
  },
};
</script>
