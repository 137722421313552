import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home-View.vue";

const routes = [
  {
    path: "/",
    name: "homeRides",
    component: Home,
  },
  {
    path: "/search",
    name: "SearchResults",
    component: () => import("../views/SearchResults.vue"),
  },
  {
    path: "/Maps",
    name: "Maps",
    component: () => import("../views/MapsView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
});

export default router;
