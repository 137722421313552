import es from "./es.json";
import en from "./en.json";
import fr from "./fr.json";
import de from "./de.json";

const messages = {
  es,
  en,
  fr,
  de,
};

export default messages;
